import React from 'react'
import Layout from "../components/Layout";

import '../styles/about.css'

import AboutImage from "../images/work.png"

import Cta from "../components/Cta";
import ContactBg from "../images/working-together-2.png"

function AboutPage() {
  return (
    <Layout>
      <div className="about-page-container container">
        <div className="about-image" style={{ backgroundImage: `url(${AboutImage})`}}></div>

        <div className="about-content">
          <div className="section-title">Our Story.</div>

          <div className="content-description">
            I founded ETD Interactive in 2018 after I left my job was a full stack web software engineer at Blizzard Entertainment in Irvine, California. Throughout my career I had held cross disciplinary roles at multiple Fortune 500 tech companies including Apple and Facebook that allowed me to gain expertise across design, development and marketing.
          </div>

          <div className="content-description">
            There was strong demand for somebody who could work on  both the design and development sides of a project. It saved on miscommunication shaved significant time and costs off of delivering the project to completion. 
          </div> 

          <div className="content-description">
            While I was working full time, a few friends reached out to me to ask if I could help them design and build a few websites they needed for a small business that they were starting. So I worked on them in my free time and was able to quickly deliver beautiful and lean websites for them. They were thrilled with the work. 
          </div> 

          <div className="content-description">
            Afterwards they referred me to other people who needed similar work done, and that’s when I realized the demand for trustworthy web designers and developers with industry experience that weren’t already soaked up by a huge tech corporation. It made it extremely difficult for small to mid sized businesses to find somebody with integrity that could help them create their online presence. 
          </div> 

          <div className="content-description">
            So I took the plunge, left my comfortable full time tech job, dived into the world of business and founded ETD Interactive. We now serve businesses in Irvine and Orange County with creating and maintaining their digital presence.
          </div> 

        </div>
      </div>

      <hr className="divider" />

      <Cta title="interested in working with us?" buttonText="contact" link="/contact" bg={ContactBg}/>

    </Layout>
  )
}

export default AboutPage;
